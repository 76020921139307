import { Helmet } from 'react-helmet';
import HomeBanner from "./homeBanner";
import HomeAbout from "./homeAbout";
import HomeService from "./homeService";
import HomeAboutSection from "./homeAboutSection";
import HomeBlog from "./homeBlog";
import HomeParallax from "./homeParallax";
import HomeTestimonials from "./homeTestimonials";
import Footer from "../footer/footer";


function Home(){
    return (
        <>
        <Helmet>
            <title>Dr.Naturals</title>
            <meta name="description" content="Description" />
        </Helmet>
        <HomeBanner></HomeBanner>
        <HomeAbout></HomeAbout>
        {/* <HomeService></HomeService> */}
        <HomeAboutSection></HomeAboutSection>
        <HomeBlog></HomeBlog>
        <HomeParallax></HomeParallax>
        <HomeTestimonials></HomeTestimonials>
        <Footer></Footer>
        </>
    );
}

export default Home;