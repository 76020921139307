import "../../style/main.css";
import "../../style/style.css"
import { Link } from 'react-router-dom';
import footerLogo from "../../images/logo.png"
import esightLogo from "../../images/esight.png"
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

function Footer(){
    return (
        <footer>
            <article className="footerHeadOverlay">
                <article className="footerHeadOverlayBox1"></article>
                <article className="footerHeadOverlayBox2">
                    <article className="footerHeadOverlayBox2Layer"></article>
                </article>
            </article>
            <article className="container">
                <article className="footerMain">
                    <article className="footerCol1">
                        <article className="footerLogo">
                            <img src={footerLogo} alt="" />
                        </article>
                        <p>Dr. Naturals Lifestyle Clinic is a holistic healthcare center in Cochin. We offer acupuncture, yoga, naturopathy, and more to promote balanced health and healing.</p>
                        <article className="footerSocialLinks">
                            <span>Social Links</span>
                            <Link to="" target="_blank" rel="noopener noreferrer">
                                <FacebookIcon></FacebookIcon>
                            </Link>
                            <Link to="" target="_blank" rel="noopener noreferrer">
                                <InstagramIcon></InstagramIcon>
                            </Link>
                            <Link to="" target="_blank" rel="noopener noreferrer">
                                <LinkedInIcon></LinkedInIcon>
                            </Link>
                        </article>
                    </article>
                    <article className="footerCol2">
                        <h2>Quick Links</h2>
                        <ul>
                            <li>
                                <span></span>
                                <Link to={`/`}>Home</Link>
                            </li>
                            <li>
                                <span></span>
                                <Link to={`/About`}>About</Link>
                            </li>
                            <li>
                                <span></span>
                                <Link to={`/Service`}>Service</Link>
                            </li>
                            <li>
                                <span></span>
                                <Link to={`/Blogs`}>Blogs</Link>
                            </li>
                            <li>
                                <span></span>
                                <Link to={`/Contact`}>Contact</Link>
                            </li>
                        </ul>
                        <aticle className="footerTiming">
                            <span>Timings</span>
                            <ul>
                                <li>
                                    <article className="footerTimingCol1">Mon - Sat</article>
                                    <article className="footerTimingCol2">: 8:30 am - 8:30 pm</article>
                                </li>
                                <li>
                                    <article className="footerTimingCol1">Sun</article>
                                    <article className="footerTimingCol2">: Closed</article>
                                </li>
                            </ul>
                        </aticle>
                    </article>
                    <article className="footerCol3">
                        <h2>Connect Us</h2>
                        <ul>
                            <li>
                                <article className="footerCol3Icon">
                                    <LocationOnIcon></LocationOnIcon>
                                </article>
                                <article className="footerCol3Content">
                                    <span>Address :</span>
                                    <p>Dr.Naturals Wellness Clinic<br/>
									G Floor, Manath Tower<br/>
									Oppo. Kalamasserry police Station<br/>
                                    S.Kalamasserry Ernakulam -682033</p>
                                </article>
                            </li>
                            <li>
                                <article className="footerCol3Icon">
                                    <PhoneIcon></PhoneIcon>
                                </article>
                                <article className="footerCol3Content">
                                    <span>Phone :</span>
                                    <Link to="tel:+918086250300">+91 7736077731</Link>
                                </article>
                            </li>
                            <li>
                                <article className="footerCol3Icon">
                                    <AlternateEmailIcon></AlternateEmailIcon>
                                </article>
                                <article className="footerCol3Content">
                                    <span>Email :</span>
                                    <Link to="mailto:drfebinrony04@gmail.com">drfebinrony04@gmail.com</Link>
                                </article>
                            </li>
                        </ul>
                    </article>
                </article>
            </article>
            <article className="footerStrip">
                <article className="container">
                    <article className="footerStripMain">
                        <article className="footerStripMainCol1">
                            <p>Copyright © 2024 Dr Natural's. All Rights Reserved.</p>
                        </article>
                        <article class="footerStripMainCol3">
                            <article class="desined"><p>Designed with</p> <FavoriteBorderIcon></FavoriteBorderIcon> <Link to="https://esightsolutions.com/" target="_blank" rel="noopener noreferrer"><article class="footerStripLogo">
                                <img src={esightLogo} alt="" />
                                    </article>
                                </Link>
                            </article>
                        </article>
                    </article>
                </article>
            </article>
        </footer>
    );
}

export default Footer;