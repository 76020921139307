
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom';

import Sidemenu from './components/sidemenu/sidemenu';
import Nav from './components/nav/nav.jsx';
import Home from './components/home/home.jsx';
import About from './components/about/about.jsx';
import Service from './components/service/service.jsx';
import InnerService from './components/service/innerService';
import Blogs from './components/blogs/blogs';
import InnerBlog from './components/blogs/innerBlog';
import Contact from './components/contact/contact';

import ScrollToTop from './components/srollTop';

function App() {
    return (
        <>
        <div className="App">
            <Sidemenu></Sidemenu>
            <Nav></Nav>
            <ScrollToTop />
            <Routes>
                <Route path={`/`} element={<Home />} />
                <Route path={`/About`} element={<About />} />
                <Route path={`/Service`} element={<Service />} />
                <Route path={`/InnerService`} element={<InnerService />} />
                <Route path={`/Blogs`} element={<Blogs />} />
                <Route path={`:id/InnerBlog`} element={<InnerBlog />} />
                <Route path={`/Contact`} element={<Contact />} />
            </Routes>
        </div>
        </>
    );
}

export default App;
