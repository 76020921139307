import "../../style/main.css";
import "../../style/style.css";

function BlogBanner(){
    return (
        <section id="blogBanner">
            <article className="blogBannerHeadOverlay">
                <article className="blogBannerHeadOverlayBox1"></article>
                <article className="blogBannerHeadOverlayBox2">
                    <article className="blogBannerHeadOverlayBox2Layer"></article>
                </article>
            </article>
            <article className="blogBannerMain">
                <h1>Latest Blogs</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia rem voluptas sunt blanditiis adipisci doloribus harum reiciendis ad repudiandae. Nihil quis, harum accusamus aspernatur dolorem pariatur est ratione earum! Blanditiis.</p>
            </article>
            <article className="blogBannerFooterOverlay">
                <article className="blogBannerFooterOverlayBox1">
                    <article className="blogBannerFooterOverlayBox1Layer"></article>
                </article>
                <article className="blogBannerFooterOverlayBox2"></article>
            </article>
        </section>
    );
}

export default BlogBanner;