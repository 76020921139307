import "../../style/main.css";
import "../../style/style.css";

function InnerServicePage(){
    return (
        <section id="innerServicePage">
            <article className="container">
                <article className="innerServicePageMain">
                </article>
            </article>
        </section>
    );
}

export default InnerServicePage;
