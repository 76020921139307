import "../../style/main.css";
import "../../style/style.css"
import { Suspense, lazy } from 'react';
import aboutMissionImg from "../../images/mission.jpg"
import aboutVisionImg from "../../images/vision.jpg"

const AboutMissionVisionImgBox = lazy(() => import("./aboutMissionVisionImgBox"));

function AboutMissionVision(){
    
    return (
        <section id="aboutMissionVision">
            <article className="container">
                <article className="aboutMissionVisionMain">
                    <article className="aboutMissionVisionBox">
                        <article className="aboutMissionVisionBoxDetails">
                            <h2>Our Mission</h2>
                            <p>Our mission at Dr.Naturals is to provide transformative, evidence-based healthcare experiences that empower individuals to unlock their innate capacity for healing and well-being. Through a comprehensive array of natural therapies and personalized guidance, we strive to address the root causes of health challenges, promote prevention, and cultivate vitality. With a commitment to collaboration, compassion, and integrity, we endeavor to create a supportive environment where every individual can thrive on their journey to optimal health and fulfillment.</p>
                        </article>
                        <article className="aboutMissionVisionBoxThumbnail">
                            <Suspense>
                                <AboutMissionVisionImgBox missionVisionImg={aboutMissionImg} />
                            </Suspense>
                        </article>
                    </article>
                    <article className="aboutMissionVisionBox">
                        <article className="aboutMissionVisionBoxDetails">
                            <h2>Our Vision</h2>
                            <p>Our vision at Dr.Naturals is to pioneer a paradigm shift in healthcare, where holistic wellness is embraced as integral to human flourishing. We envision a world where individuals are empowered to take ownership of their health, supported by a network of compassionate practitioners dedicated to personalized, evidence-based care. By fostering a culture of prevention, empowerment, and collaboration, we aspire to inspire profound transformations in health outcomes and quality of life, ultimately shaping a healthier, more vibrant future for generations to come."</p>
                        </article>
                        <article className="aboutMissionVisionBoxThumbnail">
                            <Suspense>
                                <AboutMissionVisionImgBox missionVisionImg={aboutVisionImg} />
                            </Suspense>
                        </article>
                    </article>
                </article>
            </article>
        </section>
    );
}

export default AboutMissionVision;