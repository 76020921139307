import "../../style/main.css";
import "../../style/style.css";
import WestRoundedIcon from '@mui/icons-material/WestRounded';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

function InnerBlogDetails() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();

    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        axios.get('http://drnaturalsclinic.com/fetch_blog/action/fetch_inner_blog.php', {
            params: { id }
        })
            .then(response => {
                console.log(response.data);
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [id]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (
        <section id="innerBlogDetails">
            <article className="container">
                <article className="innerBlogDetailsMain">
                    <article onClick={handleGoBack} className="backBtn">
                        <WestRoundedIcon /> Back
                    </article>
                    {data && data.map((x) => (
                        <React.Fragment key={x.id}>
                            <h1>{x.blog_tittle}</h1>
                            <p>{x.blog_description}</p>
                            <img src={x.blog_img} alt={x.blog_title} />
                            <p dangerouslySetInnerHTML={{ __html: x.blog_content }}/>
                        </React.Fragment>
                    ))}
                </article>
            </article>
        </section>
    );
}

export default InnerBlogDetails;
