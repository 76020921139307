import React from 'react';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
// Next Arrow Component
const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}>
                <ArrowForwardIosOutlinedIcon></ArrowForwardIosOutlinedIcon>
        </div>
    );
};

// Prev Arrow Component
const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}>
            <ArrowBackIosNewOutlinedIcon></ArrowBackIosNewOutlinedIcon>
        </div>
    );
};

export { NextArrow, PrevArrow };
