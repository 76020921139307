import "../../style/main.css"
import { Link } from 'react-router-dom';
import bannerImg from '../../images/bannerImg.png'
import bannerServIcon1 from '../../images/service/serv1.png'
import bannerServIcon2 from '../../images/service/serv2.png'
import bannerServIcon3 from '../../images/service/serv3.png'
import bannerServIcon4 from '../../images/service/serv4.png'
import bannerServIcon5 from '../../images/service/serv5.png'
import bannerServIcon6 from '../../images/service/serv6.png'
import bannerServIcon7 from '../../images/service/serv7.png'
import bannerServIcon8 from '../../images/service/serv8.png'
import bannerServIcon9 from '../../images/service/serv9.png'
import bannerServIcon10 from '../../images/service/serv10.png'
import bannerServIcon11 from '../../images/service/serv11.png'
import bannerServIcon12 from '../../images/service/serv12.png'

const serviceExpanBtn = (event) => {
    const allServiceBoxes = document.querySelectorAll('.homeBannerServiceBoxBody');
    const clickedServiceBox = event.currentTarget.querySelector('.homeBannerServiceBoxBody');
    const isVisible = clickedServiceBox.style.visibility === 'visible';
    
    // Hide all service boxes
    allServiceBoxes.forEach(serviceBox => {
        serviceBox.style.transition = '.3s';
        serviceBox.style.padding = '0px';
        serviceBox.style.height = '0';
        serviceBox.style.marginTop = '0';
        serviceBox.style.visibility = 'hidden';
        serviceBox.style.opacity = '0';
    });

    // Toggle the clicked service box
    if (!isVisible) {
        clickedServiceBox.style.transition = '.3s';
        clickedServiceBox.style.padding = '20px';
        clickedServiceBox.style.height = 'auto';
        clickedServiceBox.style.marginTop = '10px';
        clickedServiceBox.style.visibility = 'visible';
        clickedServiceBox.style.opacity = '1';
    }
};

function HomeBanner(){
    return (
        <header id="homeBanner">
            <article className="container">
                <article className="bannerMain">
                    <div className="homeBannerThumbnailMob">
                        <img src={bannerImg} alt="" />
                    </div>
                    <div className="bannerDetails">
                        <h1>Get Healthy<br />With Gut Health</h1>
                        <Link className="homeBannerBtn" to={`/Contact`}>Contact Now</Link>
                    </div>
                </article>
            </article>
            <div className="homeBannerThumbnail">
                <img src={bannerImg} alt="" />
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f4ffe5" fill-opacity="1" d="M0,256L48,234.7C96,213,192,171,288,154.7C384,139,480,149,576,154.7C672,160,768,160,864,160C960,160,1056,160,1152,144C1248,128,1344,96,1392,80L1440,64L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
            <div className="container">
                <div className="homeBannerService">
                    <h2>We help you holistically using</h2>
                    <p>Functional medicine emphasizing root cause approach with Nutrition, Dietary Supplements, Ozone Therapy, Hydrogen Therapy, Yoga, Acupuncture for balancing your vitality, and longterm healing.</p>
                    <div className="homeBannerServiceList">
                        <div className="homeBannerServiceBox" onClick={serviceExpanBtn}>
                            <div className="homeBannerServiceBoxHead">
                                <div className="homeBannerServiceBoxIcon">
                                    <img src={bannerServIcon1} alt="" />
                                </div>
                                <h3>Functional Medicine</h3>
                            </div>
                            <div className="homeBannerServiceBoxBody">
                                <p>Functional medicine focuses on identifying and addressing the root causes of disease, emphasizing personalized care. It integrates traditional medical practices with alternative therapies to achieve optimal health and well-being.</p>
                            </div>
                        </div>
                        <div className="homeBannerServiceBox" onClick={serviceExpanBtn}>
                            <div className="homeBannerServiceBoxHead">
                                <div className="homeBannerServiceBoxIcon">
                                    <img src={bannerServIcon2} alt="" />
                                </div>
                                <h3>Lifestyle Management</h3>
                            </div>
                            <div className="homeBannerServiceBoxBody">
                                <p>Lifestyle management involves making conscious decisions to improve overall health through balanced nutrition, exercise, and stress reduction. It empowers individuals to lead healthier lives by adopting sustainable habits and behaviors.</p>
                            </div>
                        </div>
                        <div className="homeBannerServiceBox" onClick={serviceExpanBtn}>
                            <div className="homeBannerServiceBoxHead">
                                <div className="homeBannerServiceBoxIcon">
                                    <img src={bannerServIcon3} alt="" />
                                </div>
                                <h3>Nutritional Supplement</h3>
                            </div>
                            <div className="homeBannerServiceBoxBody">
                                <p>Nutritional supplements provide essential vitamins and minerals that might be missing from a person's diet, supporting overall health. They are used to fill nutritional gaps and enhance the body's natural functions.</p>
                            </div>
                        </div>
                        <div className="homeBannerServiceBox" onClick={serviceExpanBtn}>
                            <div className="homeBannerServiceBoxHead">
                                <div className="homeBannerServiceBoxIcon">
                                    <img src={bannerServIcon4} alt="" />
                                </div>
                                <h3>Ozone Therapy</h3>
                            </div>
                            <div className="homeBannerServiceBoxBody">
                                <p>Ozone therapy uses ozone gas to boost the body's oxygen levels, promoting healing and detoxification. This alternative treatment is believed to improve immune function and treat various health conditions.</p>
                            </div>
                        </div>
                        <div className="homeBannerServiceBox" onClick={serviceExpanBtn}>
                            <div className="homeBannerServiceBoxHead">
                                <div className="homeBannerServiceBoxIcon">
                                    <img src={bannerServIcon5} alt="" />
                                </div>
                                <h3>Hydrogen Therapy</h3>
                            </div>
                            <div className="homeBannerServiceBoxBody">
                                <p>Hydrogen therapy involves inhaling hydrogen gas or drinking hydrogen-infused water to reduce oxidative stress and inflammation. It is considered a promising treatment for a range of diseases due to its antioxidant properties.</p>
                            </div>
                        </div>
                        <div className="homeBannerServiceBox" onClick={serviceExpanBtn}>
                            <div className="homeBannerServiceBoxHead">
                                <div className="homeBannerServiceBoxIcon">
                                    <img src={bannerServIcon6} alt="" />
                                </div>
                                <h3>Acupuncture</h3>
                            </div>
                            <div className="homeBannerServiceBoxBody">
                                <p>Acupuncture is a traditional Chinese medicine technique that involves inserting thin needles into specific points on the body to relieve pain and stress. It is used to balance the body's energy flow, known as Qi, and promote natural healing.</p>
                            </div>
                        </div>
                        <div className="homeBannerServiceBox" onClick={serviceExpanBtn}>
                            <div className="homeBannerServiceBoxHead">
                                <div className="homeBannerServiceBoxIcon">
                                    <img src={bannerServIcon7} alt="" />
                                </div>
                                <h3>Naturopathy</h3>
                            </div>
                            <div className="homeBannerServiceBoxBody">
                                <p>Naturopathy emphasizes natural remedies and the body's ability to heal itself, combining nutrition, herbal medicine, and lifestyle counseling. It aims to treat the whole person, addressing physical, emotional, and spiritual aspects of health.</p>
                            </div>
                        </div>
                        <div className="homeBannerServiceBox" onClick={serviceExpanBtn}>
                            <div className="homeBannerServiceBoxHead">
                                <div className="homeBannerServiceBoxIcon">
                                    <img src={bannerServIcon8} alt="" />
                                </div>
                                <h3>Ayurveda</h3>
                            </div>
                            <div className="homeBannerServiceBoxBody">
                                <p>Ayurveda is an ancient Indian system of medicine that uses diet, herbs, and lifestyle practices to balance the body's energies. It focuses on preventive care and holistic healing tailored to an individual's constitution, or dosha.</p>
                            </div>
                        </div>
                        <div className="homeBannerServiceBox" onClick={serviceExpanBtn}>
                            <div className="homeBannerServiceBoxHead">
                                <div className="homeBannerServiceBoxIcon">
                                    <img src={bannerServIcon9} alt="" />
                                </div>
                                <h3>Yoga Therapy</h3>
                            </div>
                            <div className="homeBannerServiceBoxBody">
                                <p>Yoga therapy uses yoga practices like postures, breathing exercises, and meditation to improve physical and mental health. It is a personalized approach to healing that addresses specific health concerns and enhances well-being.</p>
                            </div>
                        </div>
                        <div className="homeBannerServiceBox" onClick={serviceExpanBtn}>
                            <div className="homeBannerServiceBoxHead">
                                <div className="homeBannerServiceBoxIcon">
                                    <img src={bannerServIcon10} alt="" />
                                </div>
                                <h3>Personalized Diet Plans</h3>
                            </div>
                            <div className="homeBannerServiceBoxBody">
                                <p>Personalized diet plans are tailored to an individual's unique nutritional needs, preferences, and health goals. They aim to optimize health by considering factors such as metabolism, allergies, and lifestyle.</p>
                            </div>
                        </div>
                        <div className="homeBannerServiceBox" onClick={serviceExpanBtn}>
                            <div className="homeBannerServiceBoxHead">
                                <div className="homeBannerServiceBoxIcon">
                                    <img src={bannerServIcon11} alt="" />
                                </div>
                                <h3>Counselling</h3>
                            </div>
                            <div className="homeBannerServiceBoxBody">
                                <p>Counseling provides emotional support and guidance, helping individuals navigate life's challenges and improve mental well-being. It fosters self-awareness and personal growth through open communication and reflection.</p>
                            </div>
                        </div>
                        <div className="homeBannerServiceBox" onClick={serviceExpanBtn}>
                            <div className="homeBannerServiceBoxHead">
                                <div className="homeBannerServiceBoxIcon">
                                    <img src={bannerServIcon12} alt="" />
                                </div>
                                <h3>Chiropractise for Backpain, Neck Pain & Disc Problems</h3>
                            </div>
                            <div className="homeBannerServiceBoxBody">
                                <p>Chiropractic care involves manual adjustments to the spine and joints, alleviating back pain, neck pain, and disc problems. It focuses on restoring proper alignment and function to enhance the body's natural healing abilities.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HomeBanner;