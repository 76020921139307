import "../../style/main.css";
import "../../style/style.css"
// import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
// import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
// import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
// import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';

function HomeAbout(){
    return (
        <section id="homeAbout">
            <article className="homeAboutHeadOverlay">
                <article className="homeAboutHeadOverlayBox1"></article>
                <article className="homeAboutHeadOverlayBox2">
                    <article className="homeAboutHeadOverlayBox2Layer"></article>
                </article>
            </article>
            <article className="container">
                <article className="homeAboutMain">
                    <article className="homeAboutDetails">
                        <h2>Why we are better than others</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus facere aliquid labore illum optio perferendis voluptatibus magnam repudiandae, alias asperiores. Sapiente excepturi laborum sequi fuga, magnam alias animi aut. Corporis.</p>
                    </article>
                    <article className="homeAboutPoints">
                        <article className="homeAboutPointsBox">
                            <article className="homeAboutPointsBoxIcon">
                                <EnergySavingsLeafOutlinedIcon></EnergySavingsLeafOutlinedIcon>
                            </article>
                            <h3>What Makes Us Different Than Other Health Clinics?</h3>
                            <p>At Central Coast Center for Integrative Health, we see patients as the individuals they are. No two patients are ever exactly the same and therefore need to be treated differently. This customized approach allows us to treat you, not just the disease. Because we know that addressing your unique underlying problems is the key to reversing your condition. We offer holistic care for people with a whole-list of concerns.</p>
                        </article>
                        <article className="homeAboutPointsBox">
                            <article className="homeAboutPointsBoxIcon">
                                <CheckCircleOutlineOutlinedIcon></CheckCircleOutlineOutlinedIcon>
                            </article>
                            <h3>Our Unique Approach to Reversing Your Chronic Health Concerns</h3>
                            <p>We strive to fully understand the needs and concerns of our patients and we will work closely with you to ensure your health is restored. We offer extensive diagnostic testing, analysis and personalized treatment plans as well as an utmost dedication to helping you get better. We are committed to meeting your needs and providing you with the highest quality care.  Your health is our passion and our number one priority!</p>
                        </article>
                        <article className="homeAboutPointsBox">
                            <article className="homeAboutPointsBoxIcon">
                                <SettingsSuggestOutlinedIcon></SettingsSuggestOutlinedIcon>
                            </article>
                            <h3>Our Philosophy Utilizing Functional Medicine</h3>
                            <p>Functional medicine is a patient-centered approach that focuses on the underlying root causes of dysfunction, not just the symptoms. It is the most contemporary form of modern healthcare. There is a shift from a disease-centered focus to a patient-centered approach. A functional medicine doctor listens and spends time with the patient to determine interactions among genetic, environmental, and lifestyle factors that can influence long-term complex health factors.</p>
                        </article>
                    </article>
                </article>
            </article>
            <article className="homeAboutFooterOverlay">
                <article className="homeAboutFooterOverlayBox1">
                    <article className="homeAboutFooterOverlayBox1Layer"></article>
                </article>
                <article className="homeAboutFooterOverlayBox2">
                </article>
            </article>
        </section>
    );
}

export default HomeAbout;