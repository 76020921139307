
import Footer from "../footer/footer";
import InnerBlogDetails from "./innerBlogDetails";
import RelatedBlogs from "./relatedBlogs";

function InnerBlog(){
    return (
        <>
        <InnerBlogDetails></InnerBlogDetails>
        <RelatedBlogs></RelatedBlogs>
        <Footer></Footer>
        </>
    );
}

export default InnerBlog;