// import { Helmet } from 'react-helmet';
import AboutBanner from "./aboutBanner";
import Footer from "../footer/footer";
import AboutDetailsSection from "./aboutDetailsSection";
import AboutTeam from "./aboutTeam";
import AboutValues from "./aboutValues";
import AboutMissionVision from "./aboutMissionVison";

function About(){
    return (
        <>
        {/* <Helmet>
            <title>hello</title>
            <meta name="description" content="Description" />
        </Helmet> */}
        <AboutBanner></AboutBanner>
        <AboutDetailsSection></AboutDetailsSection>
        <AboutTeam></AboutTeam>
        <AboutValues></AboutValues>
        <AboutMissionVision></AboutMissionVision>
        <Footer></Footer>
        </>
    );
}

export default About;