import HomeService from "../home/homeService";
import Footer from "../footer/footer";

function Service(){
    return (
        <>
        <article className="ServicePageStrip"></article>
        <HomeService></HomeService>
        <Footer></Footer>
        </>
    );
}

export default Service;