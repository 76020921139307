import '../../style/main.css';
import { NavLink, useLocation } from 'react-router-dom';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Buttons from '../buttons/button';

function Sidemenu() {
    const location = useLocation();

    const isActiveService = () => {
        return location.pathname.includes(`/Service`) || location.pathname.includes(`/innerService`);
    };
    const isActiveBlog = () => {
        return location.pathname.includes(`/Blogs`) || location.pathname.includes(`/innerBlog`);
    };

    const closeSidemenu = () => {
        const sideMenu = document.querySelector('.sidemenu');
        if (sideMenu) {
            sideMenu.style.right = '-110%';
            sideMenu.style.transition = '.3s';
        }
        const shimmer = document.querySelector('.shimmer');
        if (shimmer) {
            shimmer.style.display = 'none'; // Ensure the element is displayed
            setTimeout(() => {
                shimmer.classList.remove('visible');
            }, 10); // Slight delay to trigger CSS transition
        }
    }

    const navBookBtnClik = () => {
        const sideMenu = document.querySelector('.sidemenu');
        if (sideMenu) {
            sideMenu.style.right = '-110%';
            sideMenu.style.transition = '.3s';
        }
        const bookPopup = document.querySelector('.bookPopup');
        if (bookPopup) {
            bookPopup.style.display = 'block';
        }
        const shimmer = document.querySelector('.shimmer');
        if (shimmer) {
            shimmer.style.display = 'block'; // Ensure the element is displayed
            setTimeout(() => {
                shimmer.classList.add('visible');
            }, 10); // Slight delay to trigger CSS transition
        }
    };

    const closeBookPopupClick = () => {
        const bookPopup = document.querySelector('.bookPopup');
        if (bookPopup) {
            bookPopup.style.display = 'none';
        }
        const shimmer = document.querySelector('.shimmer');
        if (shimmer) {
            shimmer.style.display = 'none';
        }
    };
    
    return (
        <aside className="sidemenu">
            <article onClick={closeSidemenu} className="closeSidemenu">
                <CloseRoundedIcon></CloseRoundedIcon>
            </article>
            <article className="sidemenuLink">
                    <ul>
                        <li>
                            <NavLink onClick={closeSidemenu} exact="true" to={`/`} end className={({ isActive }) => (isActive ? 'sidmenuLinkActive' : '')}>Home</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={closeSidemenu} to={`/About`} className={({ isActive }) => (isActive ? 'sidmenuLinkActive' : '')}>About</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={closeSidemenu} to={`/Service`} className={() => (isActiveService() ? 'sidmenuLinkActive' : '')}>Service</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={closeSidemenu} to={`/Blogs`} className={() => (isActiveBlog() ? 'sidmenuLinkActive' : '')}>Blogs</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={closeSidemenu} to={`/Contact`} className={({ isActive }) => (isActive ? 'sidmenuLinkActive' : '')}>Contact</NavLink>
                        </li>
                    </ul>
                    <article className="sidemenuContact">
                        <Buttons btnClick={navBookBtnClik} cls="sidemenuContactBtn" title="Book a Consultation"></Buttons>
                    </article>
            </article>
        </aside>
    );
}

export default Sidemenu;
