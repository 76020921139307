import "../../style/main.css";
import "../../style/style.css"
import homeBlogShape1 from "../../images/shape1.png"
import homeBlogShape2 from "../../images/shape2.png"

function HomeParallax(){
    return (
        <section id="homeParallax">
            <article className="homeParallaxShape1">
                <img src={homeBlogShape1} alt="" />
            </article>
            <article className="homeParallaxShape2">
                <img src={homeBlogShape2} alt="" />
            </article>
            <article className="container">
                <article className="homeParallaxMain">
                    <h2>Why you should choose Naturopathy</h2>
                    <p>Naturopathy is an art and science of living and healing. It is devoid of any side effects, without using any chemicals or toxins or drugs. Naturopathy makes a man healthy. Thus it is a complete science for healthcare</p>
                </article>
            </article>
        </section>
    );
}

export default HomeParallax;