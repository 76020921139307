import "../../style/main.css"
import "../../style/style.css"
import Slider from 'react-slick';
import homeBlogImg1 from "../../images/blog/img1.jpg";
import homeBlogImg2 from "../../images/blog/img2.jpg";
import homeBlogImg3 from "../../images/blog/img3.jpg";
import homeBlogImg4 from "../../images/blog/img4.jpg";
import { NextArrow, PrevArrow } from "../home/blogCarouselArrow";
import { Suspense, lazy } from 'react';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
const HomeBlogCard = lazy(() => import("../home/homeBlogCard"));

function RelatedBlogs(){
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
              breakpoint: 1025,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 481,
              settings: {
                slidesToShow: 1,
              }
            },
        ]
    };

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get('http://drnaturalsclinic.com/fetch_blog/action/fetch_blog.php') // Replace with your PHP script URL
            .then(response => {
                console.log(response.data)
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);
    console.log(data)
    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    
    return (
        <section id="relatedBlogs">
            <article className="relatedBlogsHeadOverlay">
                <article className="relatedBlogsHeadOverlayBox1">
                    <article className="relatedBlogsHeadOverlayBox2Layer"></article>
                    </article>
                <article className="relatedBlogsHeadOverlayBox2">
                </article>
            </article>
            <article className="container">
                <article className="relatedBlogTitle">
                    <h2>Related Blogs</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque, ratione consequuntur earum corrupti eveniet aliquid? Exercitationem.</p>
                </article>
                <article className="relatedBlogsMain">
              
                    <Slider {...settings}>
                    {data.map(blog => (
                        <Suspense>
                            <HomeBlogCard link={`/${blog.blog_id}/innerBlog`} blogImg={blog.blog_img} blogTitle={blog.blog_tittle} blogDesc={blog.blog_description} blogDate={blog.blog_date} />
                        </Suspense>
                         ))}
                    </Slider>
                        
                </article>
            </article>
        </section>
    );
}

export default RelatedBlogs;