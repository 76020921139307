import "../../style/main.css";
import "../../style/style.css";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import React, { useState } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";

function Contact() {
  const [isLoad, setIsLoad] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: ""
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = "Phone number must be 10 digits";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      errors.email = "Invalid email address";
    }
    if (!formData.message.trim()) {
      errors.message = "Message is required";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: ""
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoad(true);
    axios
      .post("http://drnaturalsclinic.com/fetch_blog/action/add_contact.php", formData)
      .then((response) => {
        console.log("Form submitted successfully:", response.data);
        setIsLoad(false);
        setFormData({
          name: "",
          phone: "",
          email: "",
          message: ""
        });
        setErrors({});
      })
      .catch((error) => {
        setIsLoad(false);
        console.error("There was an error submitting the form:", error);
      });
  };

  return (
    <>
      <section id="contactPage">
        <article className="container">
          <article className="contactPageTitle">
            <h1>Don't be shy, say hi!</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor
              natus vel atque amet itaque illum corrupti, eaque animi ratione
              ducimus odit ipsum error architecto.
            </p>
          </article>
          <article className="contactPageMain">
            <article className="contactPageDetails">
              <article className="contactPageDetailsBox">
                <article className="contactHeadOverlay">
                  <article className="contactHeadOverlayBox1"></article>
                  <article className="contactHeadOverlayBox2">
                    <article className="contactHeadOverlayBox2Layer"></article>
                  </article>
                </article>
                <article className="contactPageDetailsBoxMain">
                  <article className="contactPageDetailsBoxIcon">
                    <LocalPhoneOutlinedIcon></LocalPhoneOutlinedIcon>
                  </article>
                  <h3>Phone</h3>
                  <h4>Our friendly team is here to help.</h4>
                  <Link to="tel:+917736077731">+917736077731</Link>
                </article>
                <article className="contactFooterOverlay">
                  <article className="contactFooterOverlayBox1">
                    <article className="contactFooterOverlayBox1Layer"></article>
                  </article>
                  <article className="contactFooterOverlayBox2"></article>
                </article>
              </article>
              <article className="contactPageDetailsBox">
                <article className="contactHeadOverlay">
                  <article className="contactHeadOverlayBox1"></article>
                  <article className="contactHeadOverlayBox2">
                    <article className="contactHeadOverlayBox2Layer"></article>
                  </article>
                </article>
                <article className="contactPageDetailsBoxMain">
                  <article className="contactPageDetailsBoxIcon">
                    <AlternateEmailOutlinedIcon></AlternateEmailOutlinedIcon>
                  </article>
                  <h3>Email</h3>
                  <h4>Our friendly team is here to help.</h4>
                  <Link to="mailto:drfebinrony04@gmail.com">
                    drfebinrony04@gmail.com
                  </Link>
                </article>
                <article className="contactFooterOverlay">
                  <article className="contactFooterOverlayBox1">
                    <article className="contactFooterOverlayBox1Layer"></article>
                  </article>
                  <article className="contactFooterOverlayBox2"></article>
                </article>
              </article>
              <article className="contactPageDetailsBox">
                <article className="contactHeadOverlay">
                  <article className="contactHeadOverlayBox1"></article>
                  <article className="contactHeadOverlayBox2">
                    <article className="contactHeadOverlayBox2Layer"></article>
                  </article>
                </article>
                <article className="contactPageDetailsBoxMain">
                  <article className="contactPageDetailsBoxIcon">
                    <FmdGoodOutlinedIcon></FmdGoodOutlinedIcon>
                  </article>
                  <h3>Address</h3>
                  <h4>Dr.Naturals Wellness Clinic</h4>
                  <p>
                    G Floor, Manath Tower
                    <br />
                    Oppo. Kalamasserry police Station
                    <br />
                    S.Kalamasserry Ernakulam -682033
                  </p>
                </article>
                <article className="contactFooterOverlay">
                  <article className="contactFooterOverlayBox1">
                    <article className="contactFooterOverlayBox1Layer"></article>
                  </article>
                  <article className="contactFooterOverlayBox2"></article>
                </article>
              </article>
            </article>
            <article className="contactPageForm">
              <article className="contactHeadOverlay">
                <article className="contactHeadOverlayBox1"></article>
                <article className="contactHeadOverlayBox2">
                  <article className="contactHeadOverlayBox2Layer"></article>
                </article>
              </article>
              <form onSubmit={handleSubmit}>
                <article className="formGroup">
                  <label htmlFor="enq_name">Name</label>
                  <input
                    type="text"
                    id="enq_name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && <span className="error">{errors.name}</span>}
                </article>
                <article className="formGroup">
                  <label htmlFor="enq_phone">Phone</label>
                  <input
                    type="text"
                    id="enq_phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  {errors.phone && (
                    <span className="error">{errors.phone}</span>
                  )}
                </article>
                <article className="formGroup">
                  <label htmlFor="enq_email">Email</label>
                  <input
                    type="email"
                    id="enq_email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </article>
                <article className="formGroup">
                  <label htmlFor="enq_message">Message</label>
                  <textarea
                    id="enq_message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  {errors.message && (
                    <span className="error">{errors.message}</span>
                  )}
                </article>
                <article className="formBtnArea">
                  <button disabled={isLoad}>
                    {isLoad ? (
                      <BeatLoader color="rgba(255, 255, 255, 0.9)" size={8} />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </article>
              </form>
              <article className="contactFooterOverlay">
                <article className="contactFooterOverlayBox1">
                  <article className="contactFooterOverlayBox1Layer"></article>
                </article>
                <article className="contactFooterOverlayBox2"></article>
              </article>
            </article>
          </article>
          <article className="map">
            <article className="mapMain">
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.3238400106065!2d76.28552568603737!3d9.990085747732497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080d41334f3e85%3A0xe643917866f9d163!2sJudges%20Ave%20%26%205th%20Cross%20Rd%2C%20GCDA%20LIG%20Colony%2C%20Kathrikadavu%2C%20Kaloor%2C%20Ernakulam%2C%20Kochi%2C%20Kerala%20682017!5e0!3m2!1sen!2sin!4v1717040568447!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </article>
            <article className="contactFooterOverlay">
              <article className="contactFooterOverlayBox1">
                <article className="contactFooterOverlayBox1Layer"></article>
              </article>
              <article className="contactFooterOverlayBox2"></article>
            </article>
          </article>
        </article>
      </section>
      <Footer></Footer>
    </>
  );
}

export default Contact;
