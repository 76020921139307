import "../../style/main.css";
import "../../style/style.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import Slider from 'react-slick';
import { NextArrow, PrevArrow } from "./blogCarouselArrow";
import happyEmoji from "../../images/icons8-happy-96.png"

function HomeTestimonials(){
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
              breakpoint: 1025,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 1,
              }
            },
        ]
    };
    
    return (
        <section id="homeTestimonials">
            <article className="container">
                <article className="homeTestimonialsTitle">
                    <h2>Thousands of Happy Patients</h2>
                    <p>Countless satisfied patients have found renewed vitality and balance through our holistic approach at Dr. Naturals Lifestyle Clinic.</p>
                </article>
                <article className="homeTestimonialsMain">
                    <Slider {...settings}>
                            <article className="homeTestimonialsBox">
                                <article className="homeTestimonialsBoxDetails">
                                    <article className="homeTestimonialsBoxImoji">
                                        <img src={happyEmoji} alt="" />
                                    </article>
                                    <p>I am here for a week for the treatment. Really it is a rejuvenating the staff treated me very well and doctors care was excellent. I really enjoyed staying here. I recommend all of you to come and stay and experienced their hospitality.</p>
                                </article>
                                <article className="homeTestimonialsBoxProfile">
                                    <h3>Rev. Fr. Paul</h3>
                                    <h4>1516 Warwood Avc, Wheeling WV26003, U S A</h4>
                                </article>
                            </article>
                            <article className="homeTestimonialsBox">
                                <article className="homeTestimonialsBoxDetails">
                                    <article className="homeTestimonialsBoxImoji">
                                        <img src={happyEmoji} alt="" />
                                    </article>
                                    <p>It was with great joy I walked into this wonderful place. Along with Rt. Rev. Joseph Aind SDB Bishop of Dibrugrah. I have personally benefitted from the treatment. The bishop too felt the same. Staff with great heart.Dr. Febin Rony, extremely good and helpful.</p>
                                </article>
                                <article className="homeTestimonialsBoxProfile">
                                    <h3>Rev. Fr. Devassy Palatty SDB</h3>
                                    <h4>Bishop House Dibrugrah, Assam</h4>
                                </article>
                            </article>
                            <article className="homeTestimonialsBox">
                                <article className="homeTestimonialsBoxDetails">
                                    <article className="homeTestimonialsBoxImoji">
                                        <img src={happyEmoji} alt="" />
                                    </article>
                                    <p>I was delighted with the hospitality and care extended to me. Treatment was efficient and effective. The commitcdment and the dedication of the staff was exceptionally good. Dr. Febin’s personal attention is greatly appreciated. The supporting staff members are highly committed and caring.</p>
                                </article>
                                <article className="homeTestimonialsBoxProfile">
                                    <h3>Fr. Antony Nettikkattu CM</h3>
                                </article>
                            </article>
                        </Slider>
                </article>
            </article>
        </section>
    );
}

export default HomeTestimonials;