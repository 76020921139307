import '../../style/main.css';
import { NavLink, useLocation } from 'react-router-dom';
import Buttons from '../buttons/button';
import navLogo from "../../images/logo.png";
import { UilMultiply } from '@iconscout/react-unicons';
import React, { useState } from 'react';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';

function Nav() {
    const location = useLocation();

    const isActiveService = () => {
        return location.pathname.includes(`/Service`) || location.pathname.includes(`/innerService`);
    };
    const isActiveBlog = () => {
        return location.pathname.includes(`/Blogs`) || location.pathname.includes(`/innerBlog`);
    };

    const navBarClik = () => {
        const sideMenu = document.querySelector('.sidemenu');
        if (sideMenu) {
            sideMenu.style.right = '0';
            sideMenu.style.transition = '.3s';
        }
        const shimmer = document.querySelector('.shimmer');
        if (shimmer) {
            shimmer.style.display = 'block'; // Ensure the element is displayed
            setTimeout(() => {
                shimmer.classList.add('visible');
            }, 10); // Slight delay to trigger CSS transition
        }
    };

    const navBookBtnClik = () => {
        const bookPopup = document.querySelector('.bookPopup');
        if (bookPopup) {
            bookPopup.style.display = 'block';
        }
        const shimmer = document.querySelector('.shimmer');
        if (shimmer) {
            shimmer.style.display = 'block'; // Ensure the element is displayed
            setTimeout(() => {
                shimmer.classList.add('visible');
            }, 10); // Slight delay to trigger CSS transition
        }
    };

    const closeBookPopupClick = () => {
        const bookPopup = document.querySelector('.bookPopup');
        if (bookPopup) {
            bookPopup.style.display = 'none';
        }
        const shimmer = document.querySelector('.shimmer');
        if (shimmer) {
            shimmer.style.display = 'none';
        }
    };

    const [isLoad, setIsLoad] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    });
    const [errors, setErrors] = useState({});

    const validate = () => {
        const errors = {};
        if (!formData.name.trim()) {
            errors.name = "Name is required";
        }
        if (!formData.phone.trim()) {
            errors.phone = "Phone is required";
        } else if (!/^\d{10}$/.test(formData.phone)) {
            errors.phone = "Phone number must be 10 digits";
        }
        if (!formData.email.trim()) {
            errors.email = "Email is required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
            errors.email = "Invalid email address";
        }
        if (!formData.message.trim()) {
            errors.message = "Message is required";
        }
        return errors;
    };

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission

        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setIsLoad(true);
        axios.post('http://drnaturalsclinic.com/fetch_blog/action/add_consultation.php', formData)
            .then(response => {
                console.log('Form submitted successfully:', response.data);
                setIsLoad(false);
                setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    message: ''
                });
            })
            .catch(error => {
                setIsLoad(false);
                console.error('There was an error submitting the form:', error);
            });
    };

    return (
        <>
            <article className="shimmer"></article>
            <div className="bookPopup">
                <div className="closeBookPopup" onClick={closeBookPopupClick}>
                    <UilMultiply />
                </div>
                <h2>Book a Consultation</h2>
                <form onSubmit={handleSubmit}>
                    <div className="formGroup">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            
                        />
                        {errors.name && <span className="error">{errors.name}</span>}
                    </div>
                    <div className="formGroup">
                        <label htmlFor="phone">Phone</label>
                        <input
                            type="number"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            
                        />
                        {errors.phone && <span className="error">{errors.phone}</span>}
                    </div>
                    <div className="formGroup">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            
                        />
                        {errors.email && <span className="error">{errors.email}</span>}
                    </div>
                    <div className="formGroup">
                        <label htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            
                        ></textarea>
                        {errors.message && <span className="error">{errors.message}</span>}
                    </div>
                    <div className="formBtnArea">
                        <button disabled={isLoad}>
                            {isLoad ? <BeatLoader color="rgba(255, 255, 255, 0.9)" size={8} /> : 'Submit'}
                        </button>
                    </div>
                </form>
            </div>
            <nav className="nav">
                <article className="container">
                    <article className="navMain">
                        <NavLink to={`/`} className="navLogo">
                            <img src={navLogo} alt="Logo" />
                        </NavLink>
                        <article className="navLink">
                            <ul>
                                <li>
                                    <NavLink exact="true" to={`/`} end className={({ isActive }) => (isActive ? 'navLinkActive' : '')}>Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/About`} className={({ isActive }) => (isActive ? 'navLinkActive' : '')}>About</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/Service`} className={() => (isActiveService() ? 'navLinkActive' : '')}>Service</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/Blogs`} className={() => (isActiveBlog() ? 'navLinkActive' : '')}>Blogs</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/Contact`} className={({ isActive }) => (isActive ? 'navLinkActive' : '')}>Contact</NavLink>
                                </li>
                            </ul>
                        </article>
                        <article className="navContact">
                            <Buttons btnClick={navBookBtnClik} cls="navContactBtn" title="Book a Consultation"></Buttons>
                        </article>
                        <article className="navBar">
                            <article onClick={navBarClik} className="navBarBox">
                                <span></span><span></span><span></span>
                            </article>
                        </article>
                    </article>
                </article>
            </nav>
        </>
    );
}

export default Nav;
