import "../../style/main.css";
import "../../style/style.css"
import { Suspense, lazy } from 'react';
import aboutValuesImg from "../../images/values.webp"

const AboutValuesImgBox = lazy(() => import("./aboutValuesImgBox"));

function AboutValues(){
    
    return (
        <section id="aboutValues">
            <article className="container">
                <article className="aboutValuesMain">
                    <article className="aboutValuesThumbnail">
                        <Suspense>
                            <AboutValuesImgBox valuesImg={aboutValuesImg} />
                        </Suspense>
                    </article>
                    <article className="aboutValuesDetails">
                        <h4>Our Values</h4>
                        <h2>Integrity, Empowerment, Compassion, and Holistic Wellness</h2>
                        <p>At Dr.Naturals, our values serve as guiding principles, shaping every interaction and decision. Integrity underscores our commitment to honesty and transparency, fostering trust with our patients and colleagues alike. Empowerment drives us to actively involve patients in their healthcare journey, promoting autonomy and informed decision-making.</p>
                        <p>Compassion forms the heart of our practice, as we approach each individual with empathy, kindness, and understanding. Holistic wellness is our overarching goal, as we strive to address the interconnected needs of body, mind, and spirit, fostering lasting health and vitality for all.</p>
                    </article>
                </article>
            </article>
        </section>
    );
}

export default AboutValues;