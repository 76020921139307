import "../../style/main.css";
import "../../style/style.css";
import { Link } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import innerServiceBannerImg from "../../images/service/img1.jpg"

const InnerServiceBannerImg = lazy(() => import("./innerServiceBannerImg"));

function InnerServiceBanner(){
    return (
        <section id="innerServiceBanner">
            <article className="container">
                <article className="innerServiceBannerMain">
                    <article className="innerServiceBannerDetails">
                        <h1>Diet & Nutrition</h1>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, est nostrum quidem asperiores praesentium enim at earum eius accusantium quas natus saepe mollitia.</p>
                        <Link to={`/Contact`}>Contact Now</Link>
                    </article>
                    <article className="innerServiceBannerThumbnail">
                        <Suspense>
                            <InnerServiceBannerImg innerServiceBannerImg={innerServiceBannerImg} />
                        </Suspense>
                    </article>
                </article>
            </article>
        </section>
    );
}

export default InnerServiceBanner;
