import "../../style/main.css";
import "../../style/style.css";
import homeBlogImg1 from "../../images/blog/img1.jpg";
import homeBlogImg2 from "../../images/blog/img2.jpg";
import homeBlogImg3 from "../../images/blog/img3.jpg";
import homeBlogImg4 from "../../images/blog/img4.jpg";
import { Suspense, lazy } from 'react';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
const HomeBlogCard = lazy(() => import("../home/homeBlogCard"));

function BlogList() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get('http://drnaturalsclinic.com/fetch_blog/action/fetch_blog.php') // Replace with your PHP script URL
            .then(response => {
                console.log(response.data)
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);
    console.log(data)
    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (
        <section id="blogList">
            <article className="container">
                <article className="blogListMain">
                {data.map(blog => (
                    <Suspense>
                        <HomeBlogCard link={`/${blog.blog_id}/innerBlog`} blogImg={blog.blog_img} blogTitle={blog.blog_tittle} blogDesc={blog.blog_description} blogDate={blog.blog_date}/>
                    </Suspense>
                ))}
                </article>
            </article>
        </section>
    );
}

export default BlogList;