import "../../style/main.css";
import "../../style/style.css"
import { Link } from 'react-router-dom';
import { useState ,useEffect} from "react";
import aboutSectionImg from "../../images/about.jpg"

function HomeAboutSection(){
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = aboutSectionImg;
        img.onload = () => {setLoaded(true)};
    }, [aboutSectionImg]);
    return (
        <section id="homeAboutSection">
            <article className="container">
                <article className="homeAboutSectionMain">
                    <article className={`homeAboutSectionThumbnail ${loaded ? '' : 'loaded'}`}>
                        <img src={aboutSectionImg} alt="" />
                        <artilce className="homeAboutSectionThumbnailTitle">
                            <h2>Who we are ?</h2>
                        </artilce>
                    </article>
                    <article className="homeAboutSectionDetails">
                        <h2>The Wellness Zone & Healing Dominion</h2>
                        <p>Dr. Naturals Lifestyle Clinic is a holistic healthcare center in Cochin. We offer acupuncture, yoga, naturopathy, and more to promote balanced health and healing. Our evidence-based treatments focus on addressing root causes, empowering individuals to embrace healthier lifestyles for long-term well-being. Discover a path to renewed vitality with us.</p>
                        <ul>
                            <li>
                                <h3>10+</h3>
                                <h4>Years Experience</h4>
                            </li>
                            <li>
                                <h3>2000+</h3>
                                <h4>Happy Clients</h4>
                            </li>
                            <li>
                                <h3>5+</h3>
                                <h4>Skilled Doctors</h4>
                            </li>
                        </ul>
                        <Link to={`/About`}>View More</Link>
                    </article>
                </article>
            </article>
        </section>
    );
}

export default HomeAboutSection;