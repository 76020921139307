import "../../style/main.css";
import "../../style/style.css"

function AboutDetailsSection(){
    
    return (
        <section id="aboutDetailsSection">
            <article className="container">
                <article className="aboutDetailsSectionMain">
                    <p>Dr.Naturals is an innovative, holistic healthcare and Lifestyle Clinic, delivering evidence-based lifestyle-transformational health experiences using Acupuncture,Naturopathy, Yoga,Diet and Nutritional Counsellings and Nutritional Supplements in Cochin. We offers ways to create a healthier life, viable alternatives and complimentary treatment choices which are safe, effective ,suitable and also can be used in conjunction with other medical treatments and easy to incorporate into your lives.</p>
                    <p>At Dr.Naturals, we don't just find a shortcut for your problem by finding shortcut to your symptoms, we take the time to listen and uncover the underlying cause of your symptoms. Our goal is to help you feel better about your life – “TO WORK TOGETHER”- to make it healthier, more productive, and more fulfilling. Whether you are struggling with any disease, weight loss, fatigue or want to focus on prevention and wellness, we are committed to help you access your own profound capacity for renewal, healing and lasting well being.</p>
                    <ul>
                        <li>
                            <article className="aboutDetailsIcon"></article>
                            <article className="aboutDetailsCol2">
                                <h3>Holistic clinic</h3>
                                <h4>Naturopathy, acupuncture, yoga, nutrition for comprehensive wellness.</h4>
                            </article>
                        </li>
                        <li>
                            <article className="aboutDetailsIcon"></article>
                            <article className="aboutDetailsCol2">
                                <h3>Evidence-based</h3>
                                <h4>Safe, effective treatments without chemicals or toxins.</h4>
                            </article>
                        </li>
                        <li>
                            <article className="aboutDetailsIcon"></article>
                            <article className="aboutDetailsCol2">
                                <h3>Patient-centered</h3>
                                <h4>Collaborative care to address root causes of symptoms.</h4>
                            </article>
                        </li>
                        <li>
                            <article className="aboutDetailsIcon"></article>
                            <article className="aboutDetailsCol2">
                                <h3>Preventive focus</h3>
                                <h4>mpowering individuals for long-term health and vitality.</h4>
                            </article>
                        </li>
                    </ul>
                </article>
            </article>
        </section>
    );
}

export default AboutDetailsSection;