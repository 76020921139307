import BlogBanner from "./blogBanner";
import Footer from "../footer/footer";
import BlogList from "./blogList";

function Blogs(){
    return (
        <>
        <BlogBanner></BlogBanner>
        <BlogList></BlogList>
        <Footer></Footer>
        </>
    );
}

export default Blogs;