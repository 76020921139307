import "../../style/main.css";
import "../../style/style.css"
import { Suspense, lazy } from 'react';
import teamImg1 from "../../images/team/img1.jpg"
import teamImg2 from "../../images/team/img2.jpg"
import teamImg3 from "../../images/team/img3.jpg"
import teamImg4 from "../../images/team/img4.jpg"

const AboutTeamBox = lazy(() => import("./aboutTeamBox"));

function AboutTeam(){
    
    return (
        <section id="aboutTeam">
            <article className="aboutTeamHeadOverlay">
                <article className="aboutTeamHeadOverlayBox1"></article>
                <article className="aboutTeamHeadOverlayBox2">
                    <article className="aboutTeamHeadOverlayBox2Layer"></article>
                </article>
            </article>
            <article className="container">
                <article className="aboutTeamTitle">
                    <h2>Harmony Health Collective. Uniting for Wellness and Vitality</h2>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nam esse praesentium, dolores corrupti inventore beatae consequatur natus minima quasi aliquam sunt reprehenderit autem ad laudantium facere facilis.</p>
                </article>
                <article className="aboutTeamMain">
                    <Suspense>
                        <AboutTeamBox teamImg={teamImg1} teamName="Dr.Afsal Nazeer" teamPosission="MBBS, MD" />
                    </Suspense>
                    <Suspense>
                        <AboutTeamBox teamImg={teamImg2} teamName="Dr.Afsal Nazeer" teamPosission="MBBS, MD" />
                    </Suspense>
                    <Suspense>
                        <AboutTeamBox teamImg={teamImg3} teamName="Dr.Afsal Nazeer" teamPosission="MBBS, MD" />
                    </Suspense>
                    <Suspense>
                        <AboutTeamBox teamImg={teamImg4} teamName="Dr.Afsal Nazeer" teamPosission="MBBS, MD" />
                    </Suspense>
                </article>
            </article>
            <article className="aboutTeamFooterOverlay">
                <article className="aboutTeamFooterOverlayBox1">
                    <article className="aboutTeamFooterOverlayBox1Layer"></article>
                </article>
                <article className="aboutTeamFooterOverlayBox2"></article>
            </article>
        </section>
    );
}

export default AboutTeam;