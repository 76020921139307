import "../../style/main.css";
import "../../style/style.css"
import aboutBannerThumbnailShape1 from "../../images/shape4.png"
import aboutBannerThumbnailShape2 from "../../images/shape3.png"
import aboutBg from "../../images/aboutBg.webp"

function AboutBanner(){
    
    return (
        <section id="aboutBanner">
            <article className="container">
                <article className="aboutBannerMain">
                    <h1>The Wellness Zone & Healing Dominion</h1>
                    <p>Dr.Naturals: Holistic healthcare clinic offering naturopathy, acupuncture, yoga, and nutrition for comprehensive wellness solutions.</p>
                    <article className="aboutBannerThumbnail">
                        <article className="aboutBannerThumbnailShape1">
                            <img src={aboutBannerThumbnailShape1} alt="" />
                        </article>
                        <img src={aboutBg} alt="" />
                        <article className="aboutBannerThumbnailShape2">
                            <img src={aboutBannerThumbnailShape2} alt="" />
                        </article>
                    </article>
                </article>
            </article>
        </section>
    );
}

export default AboutBanner;