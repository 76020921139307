import "../../style/main.css";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import Slider from 'react-slick';
import homeBlogImg1 from "../../images/blog/img1.jpg";
import homeBlogImg2 from "../../images/blog/img2.jpg";
import homeBlogImg3 from "../../images/blog/img3.jpg";
import homeBlogImg4 from "../../images/blog/img4.jpg";
import { NextArrow, PrevArrow } from "./blogCarouselArrow";

import { useState, useEffect } from 'react';
import axios from 'axios';

import { Suspense, lazy } from 'react';
const HomeBlogCard = lazy(() => import("./homeBlogCard"));

function HomeBlog() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
              breakpoint: 1025,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 481,
              settings: {
                slidesToShow: 1,
              }
            },
        ]
    };


    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get('http://drnaturalsclinic.com/fetch_blog/action/fetch_blog.php') // Replace with your PHP script URL
            .then(response => {
                console.log(response.data)
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);
    console.log(data)
    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }
    
    return (
        <section id="HomeBlog">
            <article className="HomeBlogHeadOverlay">
                <article className="HomeBlogHeadOverlayBox1"></article>
                <article className="HomeBlogHeadOverlayBox2">
                    <article className="HomeBlogHeadOverlayBox2Layer"></article>
                </article>
            </article>
            <article className="container">
                <article className="homeBlogTitle">
                    <h2>Exploring Wellness and Healing Perspectives</h2>
                    <p>Discover insightful articles on holistic health, wellness practices, and healing perspectives for a balanced and fulfilling lifestyle journey.</p>
                    <Link to={`/Blogs`}>Explore Blogs</Link>
                </article>
                <article className="HomeBlogMain">
                    <Slider {...settings}>
                    {data.map(blog => (
                        <Suspense>
                            <HomeBlogCard link={`/${blog.blog_id}/innerBlog`} blogImg={blog.blog_img} blogTitle={blog.blog_tittle} blogDesc={blog.blog_description} blogDate={blog.blog_date} />
                        </Suspense>
                         ))}
                    </Slider>
                </article>
            </article>
            <article className="HomeBlogFooterOverlay">
                <article className="HomeBlogFooterOverlayBox1">
                    <article className="HomeBlogFooterOverlayBox1Layer"></article>
                </article>
                <article className="HomeBlogFooterOverlayBox2"></article>
            </article>
        </section>
    );
}

export default HomeBlog;
