import InnerServiceBanner from "./innerServiceBanner";
import Footer from "../footer/footer";
import InnerServicePage from "./innerServicePage";

function InnerService(){
    return (
        <>
        <InnerServiceBanner></InnerServiceBanner>
        <InnerServicePage></InnerServicePage>
        <Footer></Footer>
        </>
    );
}

export default InnerService;
